import Link from "components/Link"
import Partners from "components/Partners"
import React from "react"

const HomePage = () => {
	return (
		<>
			<div className="lg:relative">
				<div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
					<div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
						<h2 className="text-4xl tracking-normal leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl lg:text-5xl xl:text-6xl">
							Finden Sie Ihre <br className="xl:hidden" />
							<span className="text-indigo-600">Traumwohnung</span>
						</h2>
						<p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
							Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem
							cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat aliqua.
						</p>
						<div className="mt-10 sm:flex sm:justify-center lg:justify-start">
							<div className="rounded-md shadow">
								<Link
									href="/immobilien"
									className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
								>
									Immobilie finden
								</Link>
							</div>
							<div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
								<Link
									href="/kontakt"
									className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-600 bg-white hover:text-indigo-500 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
								>
									Kontakt aufnehmen
								</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
					<img
						className="absolute inset-0 w-full h-full object-cover"
						src="https://images.unsplash.com/photo-1580587771525-78b9dba3b914?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2467&q=80"
						alt="Property with pool"
					/>
				</div>
			</div>

			<Partners />

			<div className="relative py-16 bg-white overflow-hidden">
				<div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
					<div className="relative h-full text-lg max-w-prose mx-auto">
						<svg
							className="absolute top-12 left-full transform translate-x-32"
							width="404"
							height="384"
							fill="none"
							viewBox="0 0 404 384"
						>
							<defs>
								<pattern
									id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
									x="0"
									y="0"
									width="20"
									height="20"
									patternUnits="userSpaceOnUse"
								>
									<rect
										x="0"
										y="0"
										width="4"
										height="4"
										className="text-gray-200"
										fill="currentColor"
									/>
								</pattern>
							</defs>
							<rect
								width="404"
								height="384"
								fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
							/>
						</svg>
						<svg
							className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
							width="404"
							height="384"
							fill="none"
							viewBox="0 0 404 384"
						>
							<defs>
								<pattern
									id="f210dbf6-a58d-4871-961e-36d5016a0f49"
									x="0"
									y="0"
									width="20"
									height="20"
									patternUnits="userSpaceOnUse"
								>
									<rect
										x="0"
										y="0"
										width="4"
										height="4"
										className="text-gray-200"
										fill="currentColor"
									/>
								</pattern>
							</defs>
							<rect
								width="404"
								height="384"
								fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
							/>
						</svg>
						<svg
							className="absolute bottom-12 left-full transform translate-x-32"
							width="404"
							height="384"
							fill="none"
							viewBox="0 0 404 384"
						>
							<defs>
								<pattern
									id="d3eb07ae-5182-43e6-857d-35c643af9034"
									x="0"
									y="0"
									width="20"
									height="20"
									patternUnits="userSpaceOnUse"
								>
									<rect
										x="0"
										y="0"
										width="4"
										height="4"
										className="text-gray-200"
										fill="currentColor"
									/>
								</pattern>
							</defs>
							<rect
								width="404"
								height="384"
								fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
							/>
						</svg>
					</div>
				</div>
				<div className="relative px-4 sm:px-6 lg:px-8">
					<div className="text-lg max-w-prose mx-auto mb-6">
						<p className="text-base text-center leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
							Introducing
						</p>
						<h1 className="mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
							JavaScript for Beginners
						</h1>
						<p className="text-xl text-gray-500 leading-8">
							Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At
							arcu, sit dui mi, nibh dui, diam eget aliquam. Quisque id at vitae
							feugiat egestas ac. Diam nulla orci at in viverra scelerisque eget.
							Eleifend egestas fringilla sapien.
						</p>
					</div>
					<div className="prose prose-lg text-gray-500 mx-auto">
						<p>
							Faucibus commodo massa rhoncus, volutpat. <strong>Dignissim</strong> sed{" "}
							<strong>eget risus enim</strong>. Mattis mauris semper sed amet vitae
							sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra
							tellus varius sit neque erat velit. Faucibus commodo massa rhoncus,
							volutpat. Dignissim sed eget risus enim.{" "}
							<a href="#">Mattis mauris semper</a> sed amet vitae sed turpis id.
						</p>
						<ul>
							<li>Quis elit egestas venenatis mattis dignissim.</li>
							<li>Cras cras lobortis vitae vivamus ultricies facilisis tempus.</li>
							<li>Orci in sit morbi dignissim metus diam arcu pretium.</li>
						</ul>
						<p>
							Quis semper vulputate aliquam venenatis egestas sagittis quisque orci.
							Donec commodo sit viverra aliquam porttitor ultrices gravida eu.
							Tincidunt leo, elementum mattis elementum ut nisl, justo, amet, mattis.
							Nunc purus, diam commodo tincidunt turpis. Amet, duis sed elit interdum
							dignissim.
						</p>
					</div>
				</div>
			</div>
		</>
	)
}

export default HomePage
